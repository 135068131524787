<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								item-key="id"

								class="row-pointer"
								:footer-props="{'items-per-page-options':[50,100]}"
						>
							<template v-slot:[`body.prepend`]>
								<tr>
									<td>
										<v-text-field v-model="name" type="text" label="Name"></v-text-field>
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</template>
							<template v-slot:[`item.at_name`]="{ item }">
								<a @click.prevent="$router.push({name: 'Profile', params: {id: item.at_name}})" :href="`?#/community/profile/${item.at_name}`" style="text-decoration: none;color: inherit;">{{item.at_name}}</a>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'

	export default {
		computed: {
			headers() {
				return [
					{
						text: `at_name`,
						align: `left`,
						sortable: false,
						value: `at_name`,
						filter: f => (f + '').toLowerCase().includes(this[`name`].toLowerCase())
					},
					{
						text: `# posts`,
						value: `posts.count`,
					},
					{
						text: `Latest post`,
						value: `posts.latest`,
					},
					{
						text: `# comments`,
						value: `comments.count`,
					},
					{
						text: `Latest comment`,
						value: `comments.latest`,
					},
				];
			}
		},
		data: () => ({
			loading: false,
			name: ``,
			items: [],
		}),
		methods: {
			refreshContent () {
				this.loading = true

				this.$httpInt.get(`profiles/list/active-public-non-partners`)
					.then(res => this.items = res.data.result)
					.finally(() => this.loading = false)
			},
		},
		mixins: [globalMixIn],
		name: `ProfilesOfInterestActivePublicNonProfiles`,
		mounted() {
			this.refreshContent()
		},
	}
</script>
